:root {
  --background-light-blue: #ebf1f4;
  --white: #ffffff;
  --primary-500: #296262;
  /* --text-primary-color: rgba(0, 0, 0, 0.92); */
  --primaryText: #07212f;
  --secondaryText: #020309;
  --brandedText: #0d2d38;
  --goldText: #796319;
  font-size: 16px;
  color: var(--primaryText);
}
body {
  margin: 0;
  font-family: "Poppins", "DM Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-color: #f7fbfc; */
}
div {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1 {
  /* text-align: center; */
  font-size: 3rem;
}
a {
  text-decoration: none;
  color: inherit;
}
.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  /* padding: 16px; */
  /* border-radius: 16px; */
  font-weight: 600;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: scale(1);
}

.button:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.button-primary {
  background: black;
  color: white;
  /* background: linear-gradient(45deg, #027272, #22a5b8, rgba(122, 179, 199, 1)); */
}
.button-secondary {
  border: 2px solid var(--primary-500);
  color: var(--primary-500);
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 5rem);
  width: 100%;
  max-width: 1500px;
  /* padding: 1rem; */
}

.page-footer {
  box-sizing: border-box;
  width: 100%;
  background-color: var(--background-light-blue);
  padding: 2rem 1rem;
  /* text-align: center; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  color: var(--primary-500);
  font-weight: 600;
}
.page-footer-section {
  display: inline-flex;
  /* flex-direction: row; */
  align-items: center;
  gap: 0.25rem;
}

.copyable-text {
  color: var(--primary-500);

  display: inline-flex;
  align-items: center;
  /* justify-content: flex-start; */
}
.copyable-text:hover {
  cursor: pointer;
}

.plover-gold-text {
  color: var(--goldText);
  font-weight: 600;
}
.homepage-subtitle {
  font-size: 1.5rem;
  margin: 0.3rem 0;
}
.polka {
  background-color: #ebf1f4;
  opacity: 0.8;
  background-image: radial-gradient(
    #5aa09a 0.9500000000000001px,
    #ebf1f4 0.9500000000000001px
  );
  background-size: 19px 19px;
}
.gradient {
  background: linear-gradient(45deg, #027272, #269dae, #7aaaba);
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
